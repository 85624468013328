import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  constructor(private http: HttpClient) {}

  test(data) {
    return this.http.post('https://www.googleapis.com/blogger/v3/blogs/blogId/posts', data, httpOptions);
  }
}
// https://www.googleapis.com/blogger/v3/blogs/blogId/posts?blogId=2446943143175595286&key=AIzaSyBoly6Xu6DQqrNUEuLHvCqzaZ25ovaf8gY