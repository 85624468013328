import { Location } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router, ActivationStart } from '@angular/router';
import { Injectable, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
};

@Injectable({
    providedIn: 'root',
})

export class AppConfigService {
  constructor(
    private http: HttpClient,
    public location: Location,
    private router: Router,
  ) {
    this.getBlogList();
    this.getExcelList();
  }

  date = new Date();
  yearNow = this.date.getFullYear();

  blogList = {};
  image_profile_url = '';

  blogID = '2446943143175595286';
  apiKey = 'AIzaSyBoly6Xu6DQqrNUEuLHvCqzaZ25ovaf8gY';
  blogURL = 'https://www.googleapis.com/blogger/v3/blogs/blogId/posts';

  sheetURL = 'https://sheets.googleapis.com/v4/spreadsheets/1f-8rNKmUNfHWRYz4SpaPmrGMxALFzeC_655QAvx0Y_w/values/config'

  // test2() {
  //   let headers = new HttpHeaders()
  //   headers = headers.set('apikey', this.apiKey)
  //   // const result = this.http.get('https://www.googleapis.com/blogger/v3/blogs/blogId/posts',{headers });

  //   return this.http.get(this.apiURL, {headers: headers}).pipe(map((res) => {
  //     console.log("response", res);
  //     return res;
  //   }))
  // }

  checkImageExist(url, callback){
    const img= new Image();
    img.src = url;
    if (img.complete){
      callback(true);
    } else{
      img.onload= () => {
        callback(true);
      };
    }

    img.onerror = () => {
      callback(false);
    };

  }

  getExcelList() {
    const params = new HttpParams()
    .set('alt', 'json')
    .set('key', 'AIzaSyBoly6Xu6DQqrNUEuLHvCqzaZ25ovaf8gY');

    this.http.get(this.sheetURL, {params}).subscribe((response) => {
      // console.log("responseExcel", response);

      for(let eachRow of response['values']) {
        for(let eachValue of eachRow) {
          if(eachValue === 'ImageProfile') {
            this.image_profile_url = eachRow[1];
            break;
          }
        }
      }

      // console.log("url", this.image_profile_url);
    })
  }

  getBlogList() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
    })

    const params = new HttpParams()
    .set('key', 'AIzaSyBoly6Xu6DQqrNUEuLHvCqzaZ25ovaf8gY')
    .set('blogId', '2446943143175595286')
    .set('maxResults', '3');

    this.http.get(this.blogURL, { params }).subscribe(response => {

    for(let eachRow of response['items']) {
      const stringContent = eachRow['content'].split('<a href="');
      const stringContent2 = stringContent[1].split('"');
      // console.log("stringContent", stringContent);
      // console.log("stringContent2", stringContent2);

      this.checkImageExist(stringContent2[0], (exists) => {
        if (exists) {
          eachRow['image'] = stringContent2[0];
        } else {
          eachRow['image'] = 'assets/images/gallery/nopic.png';
        }
      });
    }

      this.blogList = response;
      // console.log(this.blogList);
    });
  }
}