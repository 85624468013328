<div class="container">
  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Recent works</h2>

  <div class="spacer-60"></div>

  <!-- portfolio filter (desktop) -->
  <ul class="portfolio-filter list-inline wow fadeInUp">
    <li class="list-inline-item" [ngClass]="{ current: workFilter === 'all' }" (click)="workFilter = 'all'">Everything</li>
    <li class="list-inline-item" [ngClass]="{ current: workFilter === 'gallery' }" (click)="workFilter = 'gallery'">Gallery</li>
    <li class="list-inline-item" [ngClass]="{ current: workFilter === 'project' }" (click)="workFilter = 'project'">Project</li>
    <li class="list-inline-item" disabled [ngClass]="{ current: workFilter === 'commingsoon' }" (click)="workFilter = 'commingsoon'">Comming Soon</li>
    <!-- <li class="list-inline-item" [ngClass]="{ current: workFilter === 'design' }" (click)="workFilter = 'design'">Design</li>
    <li class="list-inline-item" [ngClass]="{ current: workFilter === 'branding' }" (click)="workFilter = 'branding'">Branding</li> -->
  </ul>

  <!-- portfolio filter (mobile) -->
  <div class="pf-filter-wrapper">
    <select class="portfolio-filter-mobile" (change)="onChange($event)">
      <option value="all">Everything</option>
      <option value="gallery">Gallery</option>
      <option value="project">Project</option>
      <option value="commingsoon">Comming Soon</option>
      <!-- <option value="branding">Branding</option> -->
    </select>
  </div>

  <!-- portolio wrapper -->
  <div class="row portfolio-wrapper">
    <!-- portfolio item -->
    <!-- <div class="col-md-4 col-sm-6 grid-item art" *ngIf="workFilter === 'all' || workFilter === 'gallery'">
      <a href="javascript:" class="work-image" (click)="collegeModalCenter.show()">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">Gallery</span>
            <h4 class="title">Project at STMIK TIME</h4>
            <span class="more-button"><i class="icon-magnifier-add"></i></span>
          </div>
          <div class="thumb">
            <img src="assets/images/gallery/thumb/1.png" alt="Portfolio-title" />
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div>

    <app-ui-modal #collegeModalCenter dialogClass="modal-dialog-centered work-modal" [hideHeader]="true" [hideFooter]="true">
      <div class="app-modal-body">
        <div id="small-dialog" class="white-popup zoom-anim-dialog mfp-hide">
          <ngb-carousel *ngIf="images" [interval]="5000" [showNavigationArrows]="true">
            <ng-container *ngFor="let eachRow of sliderList">
              <ng-template ngbSlide>
                <div class="testimonial-item text-center mx-auto">

                  <img [src]="eachRow['image']" alt="customer-name" class="image-modal" />
                  <h4 class="mt-3 mb-0">{{eachRow['title']}}</h4>
                  <span class="subtitle">{{eachRow['subtitle']}}</span>
                  <div class="bg-white padding-30 shadow-dark rounded triangle-top position-relative mt-4">
                    <p class="mb-0">
                      {{eachRow['content']}}
                    </p>
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </ngb-carousel>
        </div>
      </div>
    </app-ui-modal> -->

    <div class="col-md-4 col-sm-6 grid-item art" *ngIf="workFilter === 'all' || workFilter === 'project'">
      <a href="/" target="_blank" class="work-image">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">Project</span>
            <h4 class="subtitle">STMIK TIME - Project Purchasing using VB.Net and Microsoft Access as DB</h4>
            <span class="more-button"><i class="icon-link"></i></span>
          </div>
          <div class="thumb">
            <!-- <app-gallery #singleGallery [albums]="singleGallery1"></app-gallery> -->
            <img src="assets/images/gallery/pictures/project-uas.png" alt="Portfolio-title" />
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div>

    <div class="col-md-4 col-sm-6 grid-item art" *ngIf="workFilter === 'all' || workFilter === 'project'">
      <a href="/" target="_blank" class="work-image">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">Project</span>
            <h4 class="subtitle">STMIK TIME - Undergraduate thesis Arduino earthquake detection uses a gyro component with an earthquake scale above 4 on the Richter Scale</h4>
            <span class="more-button"><i class="icon-link"></i></span>
          </div>
          <div class="thumb">
            <!-- <app-gallery #singleGallery [albums]="singleGallery1"></app-gallery> -->
            <img src="assets/images/gallery/pictures/project-skripsi.jpg" alt="Portfolio-title" />
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div>

    <div class="col-md-4 col-sm-6 grid-item art" *ngIf="workFilter === 'all' || workFilter === 'project'">
      <a href="https://sandbox.yugosh.dev/" target="_blank" class="work-image">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">Project</span>
            <h4 class="subtitle">Project MEAN (MongoDB ExpressJS Angular NodeJS)</h4>
            <span class="more-button"><i class="icon-link"></i></span>
          </div>
          <div class="thumb">
            <!-- <app-gallery #singleGallery [albums]="singleGallery1"></app-gallery> -->
            <img src="assets/images/gallery/thumb/sandbox.png" alt="Portfolio-title" />
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div>

    <div class="col-md-4 col-sm-6 grid-item art" *ngIf="workFilter === 'all' || workFilter === 'project'">
      <a href="https://yugosh.github.io/web-news/" class="work-image">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">Project</span>
            <h4 class="title">Project Python, ExpressJS, HTML, MongoDB, Thirdparty API</h4>
            <span class="more-button"><i class="icon-link"></i></span>
          </div>
          <div class="thumb">
            <!-- <app-gallery #singleGallery [albums]="singleGallery1"></app-gallery> -->
            <img src="assets/images/gallery/thumb/news.png" alt="Portfolio-title" />
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div>

    <div class="col-md-4 col-sm-6 grid-item art" *ngIf="workFilter === 'all' || workFilter === 'project'">
      <a href="https://yugosh.github.io/web-news/" class="work-image">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">Project</span>
            <h4 class="title">Project Python, ExpressJS, Angular 14, MongoDB, NodeJS</h4>
            <span class="more-button"><i class="icon-link"></i></span>
          </div>
          <div class="thumb">
            <!-- <app-gallery #singleGallery [albums]="singleGallery1"></app-gallery> -->
            <img src="assets/images/gallery/thumb/growtopia.png" alt="Portfolio-title" />
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div>

    <div class="col-md-4 col-sm-6 grid-item art" *ngIf="workFilter === 'all' || workFilter === 'project'">
      <a href="https://register.miracle-mmo.asia/" class="work-image">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">Project</span>
            <h4 class="title">Project ExpressJS, Angular 14, MySQL, NodeJS, Discord BOT</h4>
            <span class="more-button"><i class="icon-link"></i></span>
          </div>
          <div class="thumb">
            <!-- <app-gallery #singleGallery [albums]="singleGallery1"></app-gallery> -->
            <img src="assets/images/gallery/thumb/miracle.png" alt="Portfolio-title" />
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div>

    <!-- portfolio item -->
    <!-- <div
      class="col-md-4 col-sm-6 grid-item creative design"
      *ngIf="workFilter === 'all' || workFilter === 'creative' || workFilter === 'design'"
    >
      <a href="javascript:" class="work-content" data-toggle="modal" (click)="exampleModalCenter.show()">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">Creative</span>
            <h4 class="title">Guest App Walkthrough Screens</h4>
            <span class="more-button"><i class="icon-options"></i></span>
          </div>
          <div class="thumb">
            <img src="assets/images/works/1.svg" alt="Portfolio-title" />
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div>

    <app-ui-modal #exampleModalCenter dialogClass="modal-dialog-centered work-modal" [hideHeader]="true" [hideFooter]="true">
      <div class="app-modal-body">
        <div id="small-dialog" class="white-popup zoom-anim-dialog mfp-hide">
          <img src="assets/images/single-work.svg" alt="Title" />
          <h2>Guest App Walkthrough Screens</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam hendrerit nibh in massa semper rutrum. In rhoncus eleifend mi id
            tempus.
          </p>
          <p>Donec consectetur, libero at pretium euismod, nisl felis lobortis urna, id tristique nisl lectus eget ligula.</p>
          <a href="javascript:" class="btn btn-default">View on Dribbble</a>
        </div>
      </div>
    </app-ui-modal> -->

    <!-- portfolio item -->
    <!-- <div class="col-md-4 col-sm-6 grid-item branding" *ngIf="workFilter === 'all' || workFilter === 'branding'">
      <a href="javascript:" class="work-video">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">Branding</span>
            <h4 class="title">Delivery App Wireframe</h4>
            <span class="more-button"><i class="icon-camrecorder"></i></span>
          </div>
          <div class="thumb">
            <ng-image-slider [images]="videoObject" #nav></ng-image-slider>
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div> -->

    <!-- portfolio item -->
    <!-- <div class="col-md-4 col-sm-6 grid-item creative" *ngIf="workFilter === 'all' || workFilter === 'creative'">
      <a href="javascript:" class="work-video" (click)="audioModal.show()">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">Creative</span>
            <h4 class="title">Onboarding Motivation</h4>
            <span class="more-button"><i class="icon-music-tone-alt"></i></span>
          </div>
          <div class="thumb">
            <img src="assets/images/works/4.svg" alt="Portfolio-title" />
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div>

    <app-ui-modal #audioModal dialogClass="modal-dialog-centered work-modal audio-modal" [hideHeader]="true" [hideFooter]="true">
      <div class="app-modal-body">
        <iframe
          id="music-on"
          class="mfp-iframe"
          frameborder="0"
          allowfullscreen=""
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/385068290&color=%23ff5500&
auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&
show_teaser=true&visual=true"
        ></iframe>
      </div>
    </app-ui-modal> -->

    <!-- portfolio item -->
    <!-- <div class="col-md-4 col-sm-6 grid-item art branding" *ngIf="workFilter === 'all' || workFilter === 'art' || workFilter === 'branding'">
      <a href="javascript:" class="gallery-link" (click)="albumsGallery.open(0)">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">Art, Branding</span>
            <h4 class="title">iMac Mockup Design</h4>
            <span class="more-button"><i class="icon-picture"></i></span>
          </div>
          <div class="thumb">
            <app-gallery #albumsGallery [albums]="albums"></app-gallery>
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div> -->

    <!-- portfolio item -->
    <!-- <div
      class="col-md-4 col-sm-6 grid-item creative design"
      *ngIf="workFilter === 'all' || workFilter === 'creative' || workFilter === 'design'"
    >
      <a href="javascript:" target="_blank">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">Creative, Design</span>
            <h4 class="title">Game Store App Concept</h4>
            <span class="more-button"><i class="icon-link"></i></span>
          </div>
          <div class="thumb">
            <img src="assets/images/works/6.svg" alt="Portfolio-title" />
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div> -->

    <!-- <div
      class="col-md-4 col-sm-6 grid-item creative design"
      *ngIf="workFilter === 'all' || workFilter === 'commingsoon'"
    >
      <a href="javascript:" target="_blank">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">Comming Soon</span>
            <h4 class="title">Comming Soon</h4>
            <span class="more-button"><i class="icon-link"></i></span>
          </div>
          <div class="thumb">
            <img src="assets/images/gallery/thumb/commingsoon.png" alt="Portfolio-title" />
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div>

    <div
      class="col-md-4 col-sm-6 grid-item creative design"
      *ngIf="workFilter === 'all' || workFilter === 'commingsoon'"
    >
      <a href="javascript:" target="_blank">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">Comming Soon</span>
            <h4 class="title">Comming Soon</h4>
            <span class="more-button"><i class="icon-link"></i></span>
          </div>
          <div class="thumb">
            <img src="assets/images/gallery/thumb/commingsoon.png" alt="Portfolio-title" />
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div>

    <div
      class="col-md-4 col-sm-6 grid-item creative design"
      *ngIf="workFilter === 'all' || workFilter === 'commingsoon'"
    >
      <a href="javascript:" target="_blank">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">Comming Soon</span>
            <h4 class="title">Comming Soon</h4>
            <span class="more-button"><i class="icon-link"></i></span>
          </div>
          <div class="thumb">
            <img src="assets/images/gallery/thumb/commingsoon.png" alt="Portfolio-title" />
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div> -->
  </div>

  <!-- more button -->
  <!-- <div class="load-more text-center mt-4">
    <a href="javascript:" class="btn btn-default"><i class="fas fa-spinner"></i> Load more</a>
    numbered pagination (hidden for infinite scroll)
    <ul class="portfolio-pagination list-inline d-none">
      <li class="list-inline-item">1</li>
      <li class="list-inline-item"><a href="javascript:">2</a></li>
    </ul>
  </div> -->
</div>
